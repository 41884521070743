import { useState } from 'react';
import LButton from '../LButton/LButton';
import useDownload from 'src/hooks/useDownload';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CForm,
  CFormCheck,
  CButton,
  CSpinner,
} from '@coreui/react';
import { CAlert } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilCheckCircle, cilCloudDownload } from '@coreui/icons';
import PalletsBoxes from '../PalletsBoxes/PalletsBoxes';
import api from 'src/api';
import { useDispatch } from 'react-redux';
import { setErrors } from 'src/actions';
import PropTypes from 'prop-types';

function DownloadPdfFiles({ cpoId, status, isAdminOrStaff }) {
  const [downloadId, setDownloadId] = useState(null);
  const [visibleDownload, setVisibleDownload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isProofGallonsReportDownloading, setIsProofGallonsReportDownloading] =
    useState(false);

  const [downloadLista, setDownloadLista] = useState(true);
  const [downloadPallet, setDownloadPallet] = useState(true);
  const [downloadInvoice, setDownloadInvoice] = useState(true);
  const [downloadBarcodes, setDownloadBarcodes] = useState(true);
  const [downloadProduction, setDownloadProduction] = useState(true);
  const [downloadSummary, setDownloadSummary] = useState(true);
  const [downloadPackingList, setDownloadPackingLIst] = useState(false);
  const [showPallets, setShowPallets] = useState(false);
  const [downloadCpoId, setDownloadCpoId] = useState(null);

  const dispatch = useDispatch();
  const downloader = useDownload();

  const askDownload = (id) => {
    setDownloadId(id);
    setVisibleDownload(true);
  };

  const downloadPDFs = () => {
    if (
      downloadSummary ||
      downloadBarcodes ||
      downloadInvoice ||
      downloadLista ||
      downloadProduction ||
      downloadProduction ||
      downloadPallet ||
      downloadPackingList
    ) {
      setIsDownloading(true);
      api
        .get(`/cpo/pdf-files/${downloadId}`)
        .then(({ data }) => {
          if (data.summary && downloadSummary) {
            downloadPdf(data.summary, 'summary (incl PG)');
          }
          if (data.production && downloadProduction) {
            downloadPdf(data.production, 'production');
          }
          if (data.invoice && downloadInvoice) {
            downloadPdf(data.invoice, 'invoice');
          }
          if (data.lista && downloadLista) {
            downloadPdf(data.lista, 'lista');
          }
          if (data.barcode_list && downloadBarcodes) {
            downloadPdf(data.barcode_list, 'barcode_list');
          }
          if (data.packingList && downloadPackingList) {
            downloader.download(
              `${process.env.REACT_APP_API_URL}/${data.packingList}`,
              `packing_list_${cpoId}.pdf`
            );
            downloader.download(
              `${process.env.REACT_APP_API_URL}/${data.packingListExcel}`,
              `packing_list_${cpoId}.xlsx`
            );
          }
        })
        .catch((e) => {
          dispatch(setErrors([{ message: e }]));
        })
        .finally(() => {
          setIsDownloading(false);
        });
      if (downloadPallet) {
        setDownloadCpoId(downloadId);
        setShowPallets(true);
      } else {
        setVisibleDownload(false);
      }
    } else {
      dispatch(setErrors([{ message: 'Choose at least one file.' }]));
    }
  };

  const downloadProofGallonsReport = (cpo_id) => {
    setIsProofGallonsReportDownloading(true);

    api
      .get(`cpo/proof-gallons-report/${cpo_id}`)
      .then(({ data }) => {
        downloadPdf(data.proofGallons, 'proof_gallons_report');
      })
      .catch((e) => {
        dispatch(setErrors([{ message: e }]));
      })
      .finally(() => {
        setIsProofGallonsReportDownloading(false);
      });
  };

  const downloadPdf = (dataPath, name) => {
    if (dataPath) {
      api
        .get(
          '/file/pdf',
          {
            responseType: 'blob',
            params: {
              file_path: dataPath,
            },
          },
          {
            encoding: null,
          }
        )
        .then(({ data }) => {
          const pdfBlob = new Blob([data], { type: 'application/pdf' });
          const blobUrl = window.URL.createObjectURL(pdfBlob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', `${name}-cpo-${cpoId}.pdf`);
          link.click();
          link.remove();
          URL.revokeObjectURL(blobUrl);
        });
    }
  };

  return (
    <>
      <LButton
        color='success'
        onClick={() => {
          askDownload(cpoId);
        }}
        isLoading={isDownloading}
      >
        Download PDF Files
      </LButton>
      <LButton
        color='success'
        onClick={() => {
          downloadProofGallonsReport(cpoId);
        }}
        isLoading={isProofGallonsReportDownloading}
      >
        Download Proof Gallons Report <CIcon icon={cilCloudDownload} />
      </LButton>
      <CModal
        visible={visibleDownload}
        backdrop={`static`}
        onClose={() => {
          setVisibleDownload(false);
        }}
        size='lg'
      >
        <CModalHeader>
          <CModalTitle>
            Choose the files to download - Order #{downloadId}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          {status === 'In Preparation' ? (
            <CAlert color='success' className='d-flex align-items-center'>
              <CIcon
                icon={cilCheckCircle}
                className='flex-shrink-0 me-2'
                width={24}
                height={24}
              />
              <div>
                <p className={'mb-0'}>Success! Your order has been posted.</p>
                <p>
                  You can choose to download some files now but you can download
                  them later.
                </p>
              </div>
            </CAlert>
          ) : (
            ''
          )}
          <CForm>
            <CFormCheck
              label='Lista de Recorida'
              checked={downloadLista}
              onChange={() => {
                setDownloadLista((v) => !v);
              }}
            />
            <CFormCheck
              label='Pallet Map'
              checked={downloadPallet}
              onChange={() => {
                setDownloadPallet((v) => !v);
              }}
            />
            <CFormCheck
              label='Invoice'
              checked={downloadInvoice}
              onChange={() => {
                setDownloadInvoice((v) => !v);
              }}
            />
            <CFormCheck
              label='Barcodes List'
              checked={downloadBarcodes}
              onChange={() => {
                setDownloadBarcodes((v) => !v);
              }}
            />
            <CFormCheck
              label='Production'
              checked={downloadProduction}
              onChange={() => {
                setDownloadProduction((v) => !v);
              }}
            />
            <CFormCheck
              label='Summary (incl PG)'
              checked={downloadSummary}
              onChange={() => {
                setDownloadSummary((v) => !v);
              }}
            />
            {isAdminOrStaff && (
              <CFormCheck
                label='Packing List'
                checked={downloadPackingList}
                onChange={() => {
                  setDownloadPackingLIst((v) => !v);
                }}
              />
            )}
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton
            color='danger'
            onClick={() => {
              setVisibleDownload(false);
            }}
          >
            Cancel
          </CButton>
          <LButton onClick={downloadPDFs} isLoading={isDownloading}>
            Download <CIcon icon={cilCloudDownload} />
          </LButton>
        </CModalFooter>
      </CModal>
      <CModal
        alignment='center'
        backdrop={`static`}
        size='xl'
        scrollable
        visible={showPallets}
        onClose={() => {
          // setVisible(false)
          setShowPallets(false);
        }}
      >
        <CModalHeader>
          <CModalTitle>Pallets</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <h3>Generating Pallets Map&apos;s PDF...</h3>
          <CSpinner color='primary' variant='grow' />
          <CSpinner color='secondary' variant='grow' />
          <CSpinner color='success' variant='grow' />
          <CSpinner color='danger' variant='grow' />
          <CSpinner color='warning' variant='grow' />
          <CSpinner color='info' variant='grow' />
          <CSpinner color='light' variant='grow' />
          <CSpinner color='dark' variant='grow' />
          <p>Please wait</p>

          {downloadCpoId && (
            <PalletsBoxes
              cpoId={downloadCpoId}
              onFinish={() => {
                setTimeout(() => {
                  setShowPallets(false);
                  setVisibleDownload(false);
                }, 5000);
              }}
            />
          )}
        </CModalBody>
      </CModal>
    </>
  );
}

DownloadPdfFiles.propTypes = {
  cpoId: PropTypes.any,
  status: PropTypes.string,
  isAdminOrStaff: PropTypes.bool,
};

export default DownloadPdfFiles;
